import styled, { themeGet, device } from "@styled";

export const ClientsWrapper = styled.section`
  position: relative;
  z-index: 1;
  padding: 80px 0;
`;

export const LogosList = styled.div`
  .nav {
    &-tabs {
      background: none;
      flex-wrap: wrap;
      flex-direction: initial;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 3px;
        background: #fff;
        right: 6px;
        top: 0;
        bottom: 0;
      }
    }
    &-item {
      min-width: auto;
      position: relative;

      margin: 9px 0;
      ${device.small} {
        width: auto;
      }
      &::after {
        content: "";
        background: #bfbfbf;
        position: absolute;
        top: 8px;
        bottom: 8px;
        right: 0;
        width: 1px;
      }
    }
    &-link {
      color: #000;
      font-weight: normal;
      border-right: 1px solid #bfbfbf;
      border: solid #bfbfbf;
      border-width: 1px 0;
      border-radius: 0;
      min-width: auto;
      position: relative;
      padding: 12px 30px;
      width: 100%;
      &.active,
      &:hover,
      &:focus {
        color: ${themeGet("colors.primary")};
        background: none;
        border-color: #bfbfbf;
        &::after {
          content: "";
          background: ${themeGet("colors.primary")};
          position: absolute;
          left: 2px;
          right: 2px;
          bottom: 0;
          height: 2px;
        }
      }
    }
  }
  .tab-pane {
    &.active {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      ${device.medium} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px 0;
      }
      ${device.large} {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px 0;
      }
      ${device.xlarge} {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 60px 0;
      }
    }
  }
`;

export const LogosItem = styled.div`
  text-align: center;
  display: flex;
  border-bottom: 1px solid #bfbfbf;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  ${device.medium} {
    padding: 0;
    height: 130px;
    border-bottom: 0;
    border-right: 1px solid #bfbfbf;
  }
  &:nth-child(even) {
    ${device.medium} {
      border-right: none;
    }
    ${device.large} {
      border-right: 1px solid #bfbfbf;
    }
    ${device.xlarge} {
      border-right: 1px solid #bfbfbf;
    }
  }
  &:nth-child(3n) {
    ${device.large} {
      border-right: none;
    }
    ${device.xlarge} {
      border-right: 1px solid #bfbfbf;
    }
  }
  &:nth-child(4n) {
    ${device.xlarge} {
      border-right: none;
    }
  }
  .gatsby-image-wrapper {
    height: 100%;
    max-width: 220px;
    img {
      object-fit: contain !important;
    }
  }
`;
