import React from "react";
import PropTypes from "prop-types";
import Button from "@ui/button";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Heading from "@ui/heading";
import Tabs, { Tab } from "@ui/tabs";
import { SectionTitleType, ItemType, ButtonType } from "@utils/types";
import { ClientsWrapper, LogosList, LogosItem } from "./style";

const ClientsSection = ({ data }) => {
  
  return (
    <ClientsWrapper id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            {data?.section_title && (
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
                description={data.section_title?.description}
              />
            )}
            {data?.buttons?.map(({ id, path, content, ...rest }) => (
              <Button key={id} path={path} {...rest}>
                {content}
              </Button>
            ))}
          </Col>
        </Row>
        <Row>
          <Col>
            <LogosList>
              <Tabs defaultActiveKey={data.items.title} layout={2}>
                {data &&
                  data.items?.map((el, index) => (
                    <Tab eventKey={el.title} title={el.title} key={index}>
                      {el.texts && el.texts[0].content && (
                        <Heading as="h4" mb="25px">
                          {el.texts[0]?.content}
                        </Heading>
                      )}
                      {el?.images &&
                        el.images?.map((logo, index) => (
                          <LogosItem key={index}>
                            <Image
                              src={logo?.src}
                              alt={logo?.alt || "Clients Logo"}
                              title={logo?.alt || "Clients Logo"}
                            />
                          </LogosItem>
                        ))}
                    </Tab>
                  ))}
              </Tabs>
            </LogosList>
          </Col>
        </Row>
      </Container>
    </ClientsWrapper>
  );
};

ClientsSection.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.shape(SectionTitleType),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
  }),
};

export default ClientsSection;
